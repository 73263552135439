import { css } from '@emotion/react' 
import PropTypes from 'prop-types'
import { 
    Radio,
    FormControlLabel,
    Tooltip
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons'
import { useTheme } from '@findep/microfronts-core'
import { makeStyles } from '@material-ui/core/styles';


function MultiLineRadio(props) {
    const useStyles = makeStyles({
        label: {
            width: '100%'
        }
    })

    const classes = useStyles()

    const { palette } = useTheme()
    const fullVw = css`
        width: 100%;
    `
    const disabledTextCss = css`
        ${props.disabledText ? `color: ${palette.action.disabled}`: ''};
    `
    
    const fullWidth = css`
    width: 100%;
`
    const flexStart = css`
        align-items: flex-start;
    `
    const padding = css`
        padding-top: 9px;
    `
    const flexLabel = css`
        display: flex;
        ${fullWidth}
        justify-content: space-between;
        ${flexStart}
        ${padding}
    `
    const root = css`
        ${fullVw}
        ${flexStart}
        ${disabledTextCss}
    `

    const tooltipCss = css`
        justify-self: flex-end;
        color: ${palette.action.disabled};
    `
    const textFlex = css`
        display: flex;
        flex-direction: column;
    `

    const renderLabel = () => {
        return (
            <div>
                <div css={flexLabel}>
                    <div css={textFlex}>
                        {props.text}
                    </div>
                    {props.tooltip && 
                        <Tooltip enterTouchDelay={0} arrow leaveTouchDelay={props.leaveTouchDelay || 3000} title={props.tooltip} placement="left">
                            <InfoOutlined css={tooltipCss} />
                        </Tooltip>
                    }
                </div>
                {props.extra}
            </div>
        )

    }

    return (
        <FormControlLabel 
            classes={{label: classes.label}} 
            css={root} 
            value={props.value} 
            control={<Radio 
                        name={`${props.id}-input`} 
                        id={`${props.id}-input`} 
                        selected={props.selected} 
                        disabled={props.disabled} 
                        css={disabledTextCss} 
                    />} 
            label={renderLabel()} 
        />
    )
}

MultiLineRadio.propTypes = {
    text: PropTypes.node.isRequired,
    extra: PropTypes.node,
    value: PropTypes.any,
    control: PropTypes.node,
    tooltip: PropTypes.node,
    disabled: PropTypes.bool,
    disabledText: PropTypes.bool,
    selected: PropTypes.bool,
    id: PropTypes.string,
    leaveTouchDelay: PropTypes.number,
}

export default MultiLineRadio

